import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Modal,
  Upload,
  Select,
  Button,
  Spin,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import SunEditor from 'suneditor-react';
import keyBy from 'lodash.keyby';
import { upsertRecipeMutation } from '../../graphql/recipe';
import validateImageFile from '../../utils/validateImageFile';
import LanguageTabs from '../LanguageTabs';
import { tagsQuery } from '../../graphql/tag';
import { defaultButtonList } from '../../constants';

const ModalUpsertRecipe = ({ selectedItem, modalOpen, closeModal }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const [upsertRecipe, { loading: upsertRecipeLoading }] = useMutation(
    upsertRecipeMutation
  );

  const { data } = useQuery(tagsQuery, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        ...selectedItem,
        tagIds: selectedItem.tags.map(({ id }) => id),
        // convert translations array to keyed object
        translations: keyBy(selectedItem.translations, 'languageIdentifier'),
      });
    }
  }, [selectedItem, form]);

  return (
    <Modal
      keyboard={false}
      title={`${selectedItem ? 'Edit' : 'Create'} Recipe`}
      afterClose={() => {
        form.resetFields();
        setFileList([]);
      }}
      onCancel={() => closeModal()}
      maskClosable={false}
      forceRender={true}
      visible={modalOpen}
      footer={[
        <Button
          key="cancel"
          onClick={() => closeModal()}
          disabled={upsertRecipeLoading}
        >
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={upsertRecipeLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {selectedItem ? 'Save' : 'Create'}
        </Button>,
      ]}
    >
      <Spin spinning={upsertRecipeLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinishFailed={({ errorFields }) => setValidationErrors(errorFields)}
          onFinish={async (data) => {
            await upsertRecipe({
              variables: {
                input: {
                  ...(selectedItem?.id && { id: selectedItem.id }),
                  ...data,
                  // convert transations back to array
                  translations: Object.entries(data.translations).map(
                    ([languageIdentifier, val]) => ({
                      languageIdentifier,
                      ...val,
                    })
                  ),
                },
              },
            });

            closeModal(!!!selectedItem);
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <LanguageTabs validationErrors={validationErrors}>
                {(identifier) => (
                  <div>
                    <Form.Item
                      name={['translations', identifier, 'title']}
                      label="Title"
                      rules={[{ required: true, message: 'Title is required' }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name={['translations', identifier, 'text']}
                      label="Description"
                      rules={[{ required: true, message: 'Text is required' }]}
                    >
                      <SunEditor
                        setContents={form.getFieldValue([
                          'translations',
                          identifier,
                          'text',
                        ])}
                        setOptions={{
                          height: 200,
                          buttonList: defaultButtonList,
                        }}
                      />
                    </Form.Item>
                  </div>
                )}
              </LanguageTabs>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="image"
                label="Image"
                valuePropName="file"
                getValueFromEvent={(e) => e.file}
                rules={[
                  {
                    required: !!!selectedItem,
                    message: 'Recipe image is required',
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  beforeUpload={validateImageFile}
                  fileList={fileList}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  onChange={({ fileList }) => {
                    setFileList(fileList.slice(-1));
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="tagIds"
                label="Tags"
                rules={[
                  {
                    required: true,
                    message: 'Tags are required',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  optionFilterProp="label"
                >
                  {data?.tags.map(({ id, name }) => (
                    <Select.Option key={id} value={id} label={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpsertRecipe;
