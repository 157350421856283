import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Layout, Row, Col, Spin, Empty } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import GalleryCard from '../components/GalleryCard';
import ModalUpsertGallery from '../components/ModalUpsertGallery';
import {
  galleriesQuery,
  updateGalleryDisplayOrderMutation,
  deleteGalleryMutation,
} from '../graphql/gallery';

const Galleries = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [galleries, setGalleries] = useState([]);

  const { data, loading: galleriesLoading } = useQuery(galleriesQuery);
  const [updateGalleryDisplayOrder] = useMutation(
    updateGalleryDisplayOrderMutation
  );
  const [deleteGallery] = useMutation(deleteGalleryMutation);

  useEffect(() => {
    if (data?.galleries) {
      setGalleries(data.galleries);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = () => {
    setUpsertModalOpen(false);
    setSelectedItem(null);
  };

  const SortableItem = SortableElement(({ gallery }) => {
    return (
      <Col style={{ userSelect: 'none' }}>
        <GalleryCard
          gallery={gallery}
          openModal={openModal}
          deleteGallery={async (id) => {
            await deleteGallery({
              variables: { id },
              refetchQueries: [{ query: galleriesQuery }],
            });
          }}
        />
      </Col>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row gutter={[18, 18]} style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            gallery={value}
          />
        ))}
      </Row>
    );
  });

  return (
    <Spin spinning={galleriesLoading}>
      {upsertModalOpen && (
        <ModalUpsertGallery
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="Galleries"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Gallery
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {galleries.length === 0 && <Empty />}

        <SortableList
          distance={5}
          axis="x"
          items={galleries}
          onSortEnd={async ({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) {
              return;
            }

            const sortedGalleries = arrayMove(galleries, oldIndex, newIndex);

            // update state
            setGalleries(sortedGalleries);

            // update db
            await updateGalleryDisplayOrder({
              variables: {
                input: sortedGalleries.reduce((acc, gallery, index) => {
                  acc.push({ id: gallery.id, displayOrder: index });
                  return acc;
                }, []),
              },
            });
          }}
        />
      </Layout.Content>
    </Spin>
  );
};

export default Galleries;
