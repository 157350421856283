import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import withSession from '../components/withSession';
import Navigation from '../components/Navigation';
import Login from './Login';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import Slides from './Slides';
import Certificates from './Certificates';
import Partners from './Partners';
import Tags from './Tags';
import Recipes from './Recipes';
import { Routes } from '../constants';
import Galleries from './Galleries';
import Posts from './Posts';
import Collaborators from './Collaborators';
import Settings from './Settings';

function App({ session }) {
  if (session.loading) {
    return null;
  }

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute path="/login" component={Login} />

          <Route exact path="/404">
            <NotFound />
          </Route>

          <Route>
            <Layout>
              <Layout.Sider
                collapsed
                style={{
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                }}
              >
                <Navigation />
              </Layout.Sider>

              <Layout style={{ marginLeft: 80 }}>
                <Layout.Content
                  style={{ height: '100vh', backgroundColor: 'transparent' }}
                >
                  <Switch>
                    <PrivateRoute
                      path={Routes.INDEX}
                      exact
                      component={Dashboard}
                    />

                    <PrivateRoute
                      path={Routes.SLIDES}
                      exact
                      component={Slides}
                    />

                    <PrivateRoute
                      path={Routes.GALLERIES}
                      exact
                      component={Galleries}
                    />

                    <PrivateRoute
                      path={Routes.CERTIFICATES}
                      exact
                      component={Certificates}
                    />

                    <PrivateRoute
                      path={Routes.PARTNERS}
                      exact
                      component={Partners}
                    />

                    <PrivateRoute path={Routes.TAGS} exact component={Tags} />

                    <PrivateRoute
                      path={Routes.RECIPES}
                      exact
                      component={Recipes}
                    />

                    <PrivateRoute path={Routes.POSTS} exact component={Posts} />

                    <PrivateRoute
                      path={Routes.COLLABORATORS}
                      exact
                      component={Collaborators}
                    />

                    <PrivateRoute
                      path={Routes.SETTINGS}
                      exact
                      component={Settings}
                    />

                    <Redirect to="/404" />
                  </Switch>
                </Layout.Content>
              </Layout>
            </Layout>
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default withSession(App);
