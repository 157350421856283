import { gql } from '@apollo/client';

export const partnersQuery = gql`
  query partners {
    partners: adminPartners {
      id
      displayOrder
      name
      url
      type
      imagePath
      translations {
        id
        languageIdentifier
        description
      }
      translation {
        id
        languageIdentifier
        description
      }
    }
  }
`;

export const upsertPartnerMutation = gql`
  mutation upsertPartner($input: AdminUpsertPartnerInput!) {
    upsertPartner: adminUpsertPartner(input: $input) {
      id
    }
  }
`;

export const updatePartnerDisplayOrderMutation = gql`
  mutation updatePartnerDisplayOrder(
    $input: [AdminUpdatePartnerDisplayOrderInput!]!
  ) {
    updatePartnerDisplayOrder: adminUpdatePartnerDisplayOrder(input: $input)
  }
`;

export const deletePartnerMutation = gql`
  mutation deletePartner($id: ID!) {
    deletePartners: adminDeletePartner(id: $id)
  }
`;
