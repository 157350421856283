import { PageHeader, Layout } from 'antd';
import React from 'react';

const Dashboard = () => (
  <div>
    <PageHeader
      className="page-header"
      title="VitalBerry Panou de Administrare"
    />

    <Layout.Content className="page-content">Bine ați venit</Layout.Content>
  </div>
);

export default Dashboard;
