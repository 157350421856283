import React from 'react';
import { Col, Layout, Row } from 'antd';
import LoginForm from '../components/LoginForm';

const Login = () => (
  <Layout>
    <Row justify="center" style={{ height: '100vh' }} align="middle">
      <Col xs={24} sm={16} md={12}>
        <LoginForm />
      </Col>
    </Row>
  </Layout>
);

export default Login;
