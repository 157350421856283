import React, { useState, useEffect } from 'react';
import { Tabs, Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { languagesQuery } from '../../graphql/language';
import './style.scss';
import { resourceUrl } from '../../constants';

const LanguageTabs = ({ children, validationErrors }) => {
  const [activeTab, setActiveTab] = useState(null);
  const { data, loading } = useQuery(languagesQuery);

  // set active tab on load to first language in list
  useEffect(() => {
    if (data?.languages) {
      setActiveTab(data.languages[0].identifier);
    }
  }, [data]);

  // set active tab to first language with validation errors
  useEffect(() => {
    const languageIdentifiers = data?.languages.reduce(
      (acc, { identifier }) => [...acc, identifier],
      []
    );

    validationErrors.every((validationError) => {
      const identifier = languageIdentifiers.find((identifier) =>
        validationError.name.includes(identifier)
      );

      if (identifier) {
        setActiveTab(identifier);
        return false;
      }

      return true;
    });
  }, [validationErrors, data]);

  return (
    <Spin spinning={loading}>
      <Tabs
        className="language-tabs"
        type="card"
        activeKey={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
        }}
      >
        {data?.languages.map(({ name, identifier, flagPath }) => (
          <Tabs.TabPane
            className="language-tab-pane"
            forceRender={true}
            tab={
              <span>
                <img
                  style={{ marginRight: '5px' }}
                  width="12"
                  src={`${resourceUrl}${flagPath}`}
                  alt={identifier}
                />{' '}
                {name}
              </span>
            }
            key={identifier}
          >
            {children(identifier)}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Spin>
  );
};

export default LanguageTabs;
