import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
  Modal,
  Upload,
  Input,
  Button,
  Spin,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  certificatesQuery,
  upsertCertificateMutation,
} from '../../graphql/certificate';
import validateImageFile from '../../utils/validateImageFile';

const ModalUpsertCertificate = ({ selectedItem, modalOpen, closeModal }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({ thumbnail: [], pdf: [] });

  const [
    upsertCertificate,
    { loading: upsertCertificateLoading },
  ] = useMutation(upsertCertificateMutation);

  useEffect(() => {
    if (selectedItem) {
    }
  }, [selectedItem, form]);

  return (
    <Modal
      title={`${selectedItem ? 'Edit' : 'Create'} Certificate`}
      afterClose={() => {
        form.resetFields();
        setFileList({ thumbnail: [], pdf: [] });
      }}
      onCancel={closeModal}
      maskClosable={false}
      forceRender={true}
      visible={modalOpen}
      footer={[
        <Button
          key="cancel"
          onClick={closeModal}
          disabled={upsertCertificateLoading}
        >
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={upsertCertificateLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {selectedItem ? 'Save' : 'Create'}
        </Button>,
      ]}
    >
      <Spin spinning={upsertCertificateLoading}>
        <Form
          initialValues={selectedItem}
          layout="vertical"
          form={form}
          onFinish={async (data) => {
            await upsertCertificate({
              variables: {
                input: {
                  ...(selectedItem?.id && { id: selectedItem.id }),
                  ...data,
                },
              },
              refetchQueries: [
                {
                  query: certificatesQuery,
                },
              ],
            });

            closeModal();
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Certificate Name"
                rules={[
                  { required: true, message: 'Certificate Name is required' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="type"
                label="Certificate Type"
                rules={[
                  { required: true, message: 'Certificate Type is required' },
                ]}
              >
                <Select placeholder="Select Type...">
                  <Select.Option value="EXPORT">Export</Select.Option>
                  <Select.Option value="PRODUCTION">Production</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="thumbnail"
                label="Certificate Thumbnail"
                valuePropName="file"
                getValueFromEvent={(e) => e.file}
                rules={[
                  {
                    required: !!!selectedItem,
                    message: 'Thumbnail image is required',
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  beforeUpload={validateImageFile}
                  fileList={fileList.thumbnail}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  onChange={({ fileList }) => {
                    setFileList({ ...fileList, thumbnail: fileList.slice(-1) });
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="pdf"
                label="Certificate PDF"
                valuePropName="file"
                getValueFromEvent={(e) => e.file}
                rules={[
                  {
                    required: !!!selectedItem,
                    message: 'PDF file is required',
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  accept=".pdf"
                  beforeUpload={() => false}
                  fileList={fileList.pdf}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  onChange={({ fileList }) => {
                    setFileList({ ...fileList, pdf: fileList.slice(-1) });
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpsertCertificate;
