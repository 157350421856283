import { gql } from '@apollo/client';

export const sessionQuery = gql`
  query session {
    session {
      id
      email
      firstName
    }
  }
`;
