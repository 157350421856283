import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Layout, Row, Col, Spin, Empty } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import SliderCard from '../components/SliderCard';
import ModalUpsertSlide from '../components/ModalUpsertSlide';
import {
  slidesQuery,
  updateSlideDisplayOrderMutation,
  deleteSlideMutation,
} from '../graphql/slide';

const Slides = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [slides, setSlides] = useState([]);

  const { data, loading: slidesLoading } = useQuery(slidesQuery);
  const [updateSlideDisplayOrder] = useMutation(
    updateSlideDisplayOrderMutation
  );
  const [deleteSlide] = useMutation(deleteSlideMutation);

  useEffect(() => {
    if (data?.slides) {
      setSlides(data.slides);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = () => {
    setUpsertModalOpen(false);
    setSelectedItem(null);
  };

  const SortableItem = SortableElement(({ slide }) => {
    return (
      <Col style={{ userSelect: 'none' }}>
        <SliderCard
          slide={slide}
          openModal={openModal}
          deleteSlide={async (id) => {
            await deleteSlide({
              variables: { id },
              refetchQueries: [{ query: slidesQuery }],
            });
          }}
        />
      </Col>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row gutter={[18, 18]} style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} slide={value} />
        ))}
      </Row>
    );
  });

  return (
    <Spin spinning={slidesLoading}>
      {upsertModalOpen && (
        <ModalUpsertSlide
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="Slides"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Slide
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {slides.length === 0 && <Empty />}

        <SortableList
          distance={5}
          axis="x"
          items={slides}
          onSortEnd={async ({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) {
              return;
            }

            const sortedSlides = arrayMove(slides, oldIndex, newIndex);

            // update state
            setSlides(sortedSlides);

            // update db
            await updateSlideDisplayOrder({
              variables: {
                input: sortedSlides.reduce((acc, slide, index) => {
                  acc.push({ id: slide.id, displayOrder: index });
                  return acc;
                }, []),
              },
            });
          }}
        />
      </Layout.Content>
    </Spin>
  );
};

export default Slides;
