import React from 'react';
import { Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { resourceUrl } from '../../constants';

const CertificateCard = ({ certificate, openModal, deleteCertificate }) => (
  <Card
    hoverable
    style={{ width: 240 }}
    cover={
      <img
        alt="certificate"
        src={`${resourceUrl}${certificate.thumbnailPath}`}
      />
    }
    actions={[
      <EditOutlined key="edit" onClick={() => openModal(certificate)} />,
      <Popconfirm
        title="Really delete certificate?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => deleteCertificate(certificate.id)}
      >
        <DeleteOutlined />
      </Popconfirm>,
    ]}
  >
    <Card.Meta
      title={certificate.name}
      description={`Type: ${certificate.type}`}
    />
  </Card>
);

export default CertificateCard;
