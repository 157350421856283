import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Button,
  Layout,
  Spin,
  Empty,
  Table,
  Tag,
  Dropdown,
  Menu,
  Popconfirm,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import ModalUpsertTag from '../components/ModalUpsertTag';
import { tagsQuery, deleteTagMutation } from '../graphql/tag';

const Tags = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tags, setTags] = useState([]);

  const { data, loading: tagsLoading } = useQuery(tagsQuery);
  const [deleteTag] = useMutation(deleteTagMutation);

  useEffect(() => {
    if (data?.tags) {
      setTags(data.tags);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = () => {
    setUpsertModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <Spin spinning={tagsLoading}>
      {upsertModalOpen && (
        <ModalUpsertTag
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="Tags"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Tag
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {tags.length === 0 && <Empty />}

        {tags.length > 0 && (
          <Table
            bordered
            size="small"
            rowKey={(record) => record.id}
            pagination={false}
            columns={[
              {
                title: '#',
                key: 'id',
                align: 'center',
                render: (_text, _record, index) => {
                  return index + 1;
                },
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '100%',
              },
              {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'isActive',
                align: 'center',
                render: (isActive) =>
                  isActive ? (
                    <Tag color="green">Active</Tag>
                  ) : (
                    <Tag color="red">Inactive</Tag>
                  ),
              },
              {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (_text, record) => (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => openModal(record)}>
                          Edit
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item danger>
                          <Popconfirm
                            title="Really delete tag?"
                            onConfirm={async () => {
                              await deleteTag({
                                variables: { id: record.id },
                                refetchQueries: [{ query: tagsQuery }],
                              });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            Delete...
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                    arrow
                  >
                    <Button icon={<SettingOutlined />} />
                  </Dropdown>
                ),
              },
            ]}
            dataSource={data?.tags}
          />
        )}
      </Layout.Content>
    </Spin>
  );
};

export default Tags;
