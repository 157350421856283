import { gql } from '@apollo/client';

export const galleriesQuery = gql`
  query galleries {
    galleries: adminGalleries {
      id
      youtubeLink
      displayOrder
      isDisplayedHomepage
      isDisplayedProduction
      images {
        id
        imagePath
        displayOrder
      }
      translations {
        id
        languageIdentifier
        title
        description
      }
      translation {
        id
        languageIdentifier
        title
        description
      }
    }
  }
`;

export const upsertGalleryMutation = gql`
  mutation upsertGallery($input: AdminUpsertGalleryInput!) {
    upsertGallery: adminUpsertGallery(input: $input) {
      id
    }
  }
`;

export const updateGalleryDisplayOrderMutation = gql`
  mutation updateGalleryDisplayOrder(
    $input: [AdminUpdateGalleryDisplayOrderInput!]!
  ) {
    updateGalleryDisplayOrder: adminUpdateGalleryDisplayOrder(input: $input)
  }
`;

export const deleteGalleryMutation = gql`
  mutation deleteGallery($id: ID!) {
    deleteGallery: adminDeleteGallery(id: $id)
  }
`;
