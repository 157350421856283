import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './style.scss';

const LoginForm = () => {
  const onFinish = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login-admin`,

      {
        credentials: 'include',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((r) => r.json());

    if (response.error) {
      message.error(response.message);
    }

    if (response.user) {
      // redirect to index
      window.location = '/';
    }
  };

  return (
    <div className="login-form">
      <Form onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Email required',
            },
          ]}
        >
          <Input size="large" prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Password required',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item className="login-submit">
          <Button size="large" type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
