import { gql } from '@apollo/client';

export const languagesQuery = gql`
  query languages {
    languages {
      id
      identifier
      name
      flagPath
    }
  }
`;
