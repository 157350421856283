import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Layout, Row, Col, Spin, Empty } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PartnerCard from '../components/PartnerCard';
import ModalUpsertPartner from '../components/ModalUpsertPartner';
import {
  partnersQuery,
  updatePartnerDisplayOrderMutation,
  deletePartnerMutation,
} from '../graphql/partner';

const Partners = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [partners, setPartners] = useState([]);

  const { data, loading: partnersLoading } = useQuery(partnersQuery);
  const [updatePartnerDisplayOrder] = useMutation(
    updatePartnerDisplayOrderMutation
  );
  const [deletePartner] = useMutation(deletePartnerMutation);

  useEffect(() => {
    if (data?.partners) {
      setPartners(data.partners);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = () => {
    setUpsertModalOpen(false);
    setSelectedItem(null);
  };

  const SortableItem = SortableElement(({ partner }) => {
    return (
      <Col style={{ userSelect: 'none' }}>
        <PartnerCard
          partner={partner}
          openModal={openModal}
          deletePartner={async (id) => {
            await deletePartner({
              variables: { id },
              refetchQueries: [{ query: partnersQuery }],
            });
          }}
        />
      </Col>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row gutter={[18, 18]} style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            partner={value}
          />
        ))}
      </Row>
    );
  });

  return (
    <Spin spinning={partnersLoading}>
      {upsertModalOpen && (
        <ModalUpsertPartner
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="Partners"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Partner
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {partners.length === 0 && <Empty />}

        <SortableList
          distance={5}
          axis="x"
          items={partners}
          onSortEnd={async ({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) {
              return;
            }

            const sortedPartners = arrayMove(partners, oldIndex, newIndex);

            // update state
            setPartners(sortedPartners);

            // update db
            await updatePartnerDisplayOrder({
              variables: {
                input: sortedPartners.reduce((acc, partner, index) => {
                  acc.push({ id: partner.id, displayOrder: index });
                  return acc;
                }, []),
              },
            });
          }}
        />
      </Layout.Content>
    </Spin>
  );
};

export default Partners;
