import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Button,
  Layout,
  Spin,
  Empty,
  Table,
  Dropdown,
  Menu,
  Popconfirm,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import ModalUpsertCollaborator from '../components/ModalUpsertCollaborator';
import {
  collaboratorsQuery,
  deleteCollaboratorMutation,
} from '../graphql/collaborator';

const Collaborators = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [collaborators, setCollaborators] = useState([]);

  const { data, loading: collaboratorsLoading, refetch } = useQuery(
    collaboratorsQuery
  );
  const [deleteCollaborator] = useMutation(deleteCollaboratorMutation);

  useEffect(() => {
    if (data?.collaborators) {
      setCollaborators(data.collaborators);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = (shouldRedirect) => {
    setUpsertModalOpen(false);
    setSelectedItem(null);

    // refetch data on close modal
    refetch();
  };

  return (
    <Spin spinning={collaboratorsLoading}>
      {upsertModalOpen && (
        <ModalUpsertCollaborator
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="Collaborators"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Collaborator
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {collaborators.length === 0 && <Empty />}

        {collaborators.length > 0 && (
          <Table
            bordered
            size="small"
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: '#',
                key: 'id',
                align: 'center',
                render: (_text, _record, index) => {
                  return index + 1;
                },
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '100%',
              },
              {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
                align: 'center',
              },
              {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                align: 'center',
              },

              {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (_text, record) => (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => openModal(record)}>
                          Edit
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item danger>
                          <Popconfirm
                            title="Really delete collaborator?"
                            onConfirm={async () => {
                              await deleteCollaborator({
                                variables: { id: record.id },
                              });

                              await refetch();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            Delete...
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                    arrow
                  >
                    <Button icon={<SettingOutlined />} />
                  </Dropdown>
                ),
              },
            ]}
            dataSource={collaborators}
          />
        )}
      </Layout.Content>
    </Spin>
  );
};

export default Collaborators;
