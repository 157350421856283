import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button, Switch, Spin, Input } from 'antd';
import { useMutation } from '@apollo/client';
import keyBy from 'lodash.keyby';
import { tagsQuery, upsertTagMutation } from '../../graphql/tag';
import LanguageTabs from '../LanguageTabs';

const ModalUpsertTag = ({ selectedItem, modalOpen, closeModal }) => {
  const [form] = Form.useForm();
  const [validationErrors, setValidationErrors] = useState([]);
  const [upsertTag, { loading: upsertTagLoading }] = useMutation(
    upsertTagMutation
  );

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        ...selectedItem,
        // convert translations array to keyed object
        translations: keyBy(selectedItem.translations, 'languageIdentifier'),
      });
    }
  }, [selectedItem, form]);

  return (
    <Modal
      title={`${selectedItem ? 'Edit' : 'Create'} Tag`}
      afterClose={() => {
        form.resetFields();
      }}
      onCancel={closeModal}
      maskClosable={false}
      forceRender={true}
      visible={modalOpen}
      footer={[
        <Button key="cancel" onClick={closeModal} disabled={upsertTagLoading}>
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={upsertTagLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {selectedItem ? 'Save' : 'Create'}
        </Button>,
      ]}
    >
      <Spin spinning={upsertTagLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinishFailed={({ errorFields }) => setValidationErrors(errorFields)}
          onFinish={async (data) => {
            await upsertTag({
              variables: {
                input: {
                  ...(selectedItem?.id && { id: selectedItem.id }),
                  ...data,
                  // convert transations back to array
                  translations: Object.entries(data.translations).map(
                    ([languageIdentifier, val]) => ({
                      languageIdentifier,
                      ...val,
                    })
                  ),
                },
              },
              refetchQueries: [
                {
                  query: tagsQuery,
                },
              ],
            });

            closeModal();
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <LanguageTabs validationErrors={validationErrors}>
                {(identifier) => (
                  <div>
                    <Form.Item
                      name={['translations', identifier, 'name']}
                      label="Name"
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                )}
              </LanguageTabs>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="isActive"
                label="Is Active?"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpsertTag;
