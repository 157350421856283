import React from 'react';
import { useQuery } from '@apollo/client';
import { sessionQuery } from '../graphql/session';

export const SessionContext = React.createContext({});

const SessionContextProvider = ({ children }) => {
  const { data, error, loading, updateQuery } = useQuery(sessionQuery);

  const getUserFromSessionQuery = (data, loading, error) => {
    if (error) {
      logOut();
    }

    if (loading || !data) {
      return null;
    }

    return data.session;
  };

  const logOut = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
      credentials: 'include',
      method: 'post',
    });

    updateQuery(() => {
      return {
        session: null,
      };
    });
  };

  return (
    <SessionContext.Provider
      value={{
        loading,
        user: getUserFromSessionQuery(data, loading, error),
        logOut,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
