import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { createUploadLink } from 'apollo-upload-client';

const link = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    message.error(networkError);
  }
});

export default new ApolloClient({
  link: from([errorLink, link]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
