import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import App from './pages/App';
import client from './apolloClient';
import SessionContextProvider from './contexts/SessionContextProvider';
import './index.scss';

ReactDOM.render(
  <ApolloProvider client={client}>
    <SessionContextProvider>
      <App />
    </SessionContextProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
