export const Routes = {
  INDEX: '/',
  LOGIN: '/login',
  SLIDES: '/slides',
  CERTIFICATES: '/certificates',
  PARTNERS: '/partners',
  GALLERIES: '/galleries',
  TAGS: '/tags',
  RECIPES: '/recipes',
  POSTS: '/posts',
  COLLABORATORS: '/collaborators',
  SETTINGS: '/settings',
};

export const ErrorCodes = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

export const resourceUrl = process.env.REACT_APP_API_URL;

export const ITEMS_PER_PAGE = 10;

export const counties = [
  { name: 'Alba', code: 'AB' },
  { name: 'Arad', code: 'AR' },
  { name: 'Argeș', code: 'AG' },
  { name: 'Bacău', code: 'BC' },
  { name: 'Bihor', code: 'BH' },
  { name: 'Bistrița-Năsăud', code: 'BN' },
  { name: 'Botoșani', code: 'BT' },
  { name: 'Brăila', code: 'BR' },
  { name: 'Brașov', code: 'BV' },
  { name: 'Buzău', code: 'BZ' },
  { name: 'Călărași', code: 'CL' },
  { name: 'Caraș-Severin', code: 'CS' },
  { name: 'Cluj', code: 'CJ' },
  { name: 'Constanța', code: 'CT' },
  { name: 'Covasna', code: 'CV	' },
  { name: 'Dâmbovița', code: 'DB' },
  { name: 'Dolj', code: 'DJ' },
  { name: 'Galați', code: 'GL' },
  { name: 'Giurgiu', code: 'GR' },
  { name: 'Gorj', code: 'GJ' },
  { name: 'Harghita', code: 'HR' },
  { name: 'Hunedoara', code: 'HD' },
  { name: 'Ialomița', code: 'IL' },
  { name: 'Iași', code: 'IS' },
  { name: 'Ilfov', code: 'IF' },
  { name: 'Maramureș', code: 'MM' },
  { name: 'Mehedinți', code: 'MH' },
  { name: 'Mureș', code: 'MS' },
  { name: 'Neamț', code: 'NT' },
  { name: 'Olt', code: 'OT' },
  { name: 'Prahova', code: 'PH' },
  { name: 'Sălaj', code: 'SJ' },
  { name: 'Satu Mare', code: 'SM' },
  { name: 'Sibiu', code: 'SB' },
  { name: 'Suceava', code: 'SV' },
  { name: 'Teleorman', code: 'TR' },
  { name: 'Timiș', code: 'TM' },
  { name: 'Tulcea', code: 'TL' },
  { name: 'Vâlcea', code: 'VL' },
  { name: 'Vaslui', code: 'VS' },
  { name: 'Vrancea', code: 'VN' },
];

export const techSpecFileName = 'technical-specification';

// sun editor button list
export const defaultButtonList = [
  ['formatBlock'],
  ['fontColor'],
  ['align', 'horizontalRule', 'list', 'lineHeight'],
  ['fullScreen'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  ['link', 'image', 'video', 'table'],
];
