import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Row, Col } from 'antd';
import shortid from 'shortid';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { resourceUrl } from '../../constants';
import './style.scss';

const SortableItem = SortableElement(({ file, onDelete }) => {
  const { imagePath, preview } = file;

  return (
    <Col className="thumb">
      <img
        className="thumb-img"
        src={imagePath ? `${resourceUrl}/${imagePath}` : preview}
        alt=""
      />

      <Button
        onClick={() => {
          onDelete(file);
        }}
        icon={<DeleteOutlined />}
        type="primary"
        danger
        size="small"
      />
    </Col>
  );
});

const SortableList = SortableContainer(({ fileList, setFileList }) => {
  const onDelete = (file) => {
    setFileList(fileList.filter((f) => f !== file));
  };

  return (
    <Row className="thumbs-container">
      {fileList.map((file, index) => {
        return (
          <SortableItem
            key={`item-${file.id}`}
            index={index}
            onDelete={onDelete}
            file={file}
          />
        );
      })}
    </Row>
  );
});

const GalleryUpload = ({ fileList, setFileList }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFileList(
        fileList.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              id: shortid.generate(),
              preview: URL.createObjectURL(file),
            })
          )
        )
      );
    },
  });

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <SortableList
          axis="xy"
          fileList={fileList}
          setFileList={setFileList}
          onSortEnd={async ({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) {
              return;
            }

            // update state
            setFileList(arrayMove(fileList, oldIndex, newIndex));
          }}
        />
      </aside>
    </section>
  );
};

GalleryUpload.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape()),
};

GalleryUpload.defaultProps = {
  fileList: [],
};

export default GalleryUpload;
