import React from 'react';
import { Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { resourceUrl } from '../../constants';
import truncate from '../../utils/truncate';

const SliderCard = ({ slide, openModal, deleteSlide }) => (
  <Card
    hoverable
    style={{ width: 240 }}
    cover={<img alt="example" src={`${resourceUrl}${slide.imagePath}`} />}
    actions={[
      <EditOutlined key="edit" onClick={() => openModal(slide)} />,
      <Popconfirm
        title="Really delete slide?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => deleteSlide(slide.id)}
      >
        <DeleteOutlined />
      </Popconfirm>,
    ]}
  >
    <Card.Meta
      title={slide.translation.title}
      description={truncate(slide.translation.description)}
    />
  </Card>
);

export default SliderCard;
