import React from 'react';
import { Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { resourceUrl } from '../../constants';
import truncate from '../../utils/truncate';

const GalleryrCard = ({ gallery, openModal, deleteGallery }) => (
  <Card
    hoverable
    style={{ width: 240 }}
    cover={
      <img alt="example" src={`${resourceUrl}${gallery.images[0].imagePath}`} />
    }
    actions={[
      <EditOutlined key="edit" onClick={() => openModal(gallery)} />,
      <Popconfirm
        title="Really delete gallery?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => deleteGallery(gallery.id)}
      >
        <DeleteOutlined />
      </Popconfirm>,
    ]}
  >
    <Card.Meta
      title={gallery.translation.title}
      description={truncate(gallery.translation.description)}
    />
  </Card>
);

export default GalleryrCard;
