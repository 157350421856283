import { gql } from '@apollo/client';

export const tagsQuery = gql`
  query tags {
    tags: adminTags {
      id
      name
      isActive
      translations {
        id
        languageIdentifier
        name
      }
      translation {
        id
        languageIdentifier
        name
      }
    }
  }
`;

export const upsertTagMutation = gql`
  mutation upsertTag($input: AdminUpsertTagInput!) {
    upsertTag: adminUpsertTag(input: $input) {
      id
    }
  }
`;

export const deleteTagMutation = gql`
  mutation deleteTag($id: ID!) {
    deleteTags: adminDeleteTag(id: $id)
  }
`;
