import { gql } from '@apollo/client';

export const postsQuery = gql`
  query posts($limit: Int, $offset: Int) {
    posts: adminPosts(limit: $limit, offset: $offset) {
      rows {
        id
        slug
        imagePath
        tags {
          id
          name
        }
        translations {
          id
          languageIdentifier
          title
          text
        }
        translation {
          id
          languageIdentifier
          title
          text
        }
      }
      count
    }
  }
`;

export const upsertPostMutation = gql`
  mutation upsertPost($input: AdminUpsertPostInput!) {
    upsertPost: adminUpsertPost(input: $input) {
      id
    }
  }
`;

export const deletePostMutation = gql`
  mutation deletePost($id: ID!) {
    deletePosts: adminDeletePost(id: $id)
  }
`;
