import React, { useContext } from 'react';
import { SessionContext } from '../contexts/SessionContextProvider';

const withSession = (Component) => (props) => {
  const session = useContext(SessionContext);

  return <Component session={session} {...props} />;
};

export default withSession;
