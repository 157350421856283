import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from '../constants';
import withSession from './withSession';

const PublicRoute = ({ component: Component, session, ...rest }) => {
  return (
    <Route {...rest}>
      {(props) =>
        session.user ? <Redirect to={Routes.INDEX} /> : <Component {...props} />
      }
    </Route>
  );
};

export default withSession(PublicRoute);
