import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Modal, Button, Spin, Switch } from 'antd';
import { useMutation } from '@apollo/client';
import keyBy from 'lodash.keyby';
import { galleriesQuery, upsertGalleryMutation } from '../../graphql/gallery';
import LanguageTabs from '../LanguageTabs';
import GalleryUpload from '../GalleryUpload';

const ModalUpsertGallery = ({ selectedItem, modalOpen, closeModal }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const [upsertGallery, { loading: upsertGalleryLoading }] = useMutation(
    upsertGalleryMutation
  );

  // initialize form if in edit mode
  useEffect(() => {
    if (selectedItem) {
      setFileList(selectedItem.images);

      form.setFieldsValue({
        ...selectedItem,
        // convert translations array to keyed object
        translations: keyBy(selectedItem.translations, 'languageIdentifier'),
      });
    }
  }, [selectedItem, form]);

  // update form.images on fileList update
  useEffect(() => {
    form.setFieldsValue({
      images: fileList,
    });
  }, [fileList, form]);

  return (
    <Modal
      title={`${selectedItem ? 'Edit' : 'Create'} Gallery`}
      afterClose={() => {
        form.resetFields();
        setFileList([]);
      }}
      style={{ top: '50px' }}
      onCancel={closeModal}
      maskClosable={false}
      forceRender={true}
      visible={modalOpen}
      footer={[
        <Button
          key="cancel"
          onClick={closeModal}
          disabled={upsertGalleryLoading}
        >
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={upsertGalleryLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {selectedItem ? 'Save' : 'Create'}
        </Button>,
      ]}
    >
      <Spin spinning={upsertGalleryLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinishFailed={({ errorFields }) => setValidationErrors(errorFields)}
          onFinish={async (data) => {
            await upsertGallery({
              variables: {
                input: {
                  ...(selectedItem?.id && { id: selectedItem.id }),
                  ...data,
                  // convert transations back to array
                  translations: Object.entries(data.translations).map(
                    ([languageIdentifier, val]) => ({
                      languageIdentifier,
                      ...val,
                    })
                  ),
                },
              },
              refetchQueries: [
                {
                  query: galleriesQuery,
                },
              ],
            });

            closeModal();
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <LanguageTabs validationErrors={validationErrors}>
                {(identifier) => (
                  <div>
                    <Form.Item
                      name={['translations', identifier, 'title']}
                      label="Title"
                      rules={[{ required: true, message: 'Title is required' }]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>

                    <Form.Item
                      name={['translations', identifier, 'description']}
                      label="Description"
                      rules={[
                        { required: true, message: 'Description is required' },
                      ]}
                    >
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>
                  </div>
                )}
              </LanguageTabs>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="images"
                label="Gallery Images"
                valuePropName="files"
                rules={[
                  {
                    required: true,
                    message: 'Images are required',
                  },
                ]}
              >
                <GalleryUpload fileList={fileList} setFileList={setFileList} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="youtubeLink" label="YouTube Link">
                <Input placeholder="Youtube Link" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="isDisplayedHomepage"
                label="Show on Homepage?"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="isDisplayedProduction"
                label="Show on Production page?"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpsertGallery;
