import { gql } from '@apollo/client';

export const certificatesQuery = gql`
  query certificates {
    certificates: adminCertificates {
      id
      displayOrder
      name
      pdfPath
      thumbnailPath
      type
    }
  }
`;

export const upsertCertificateMutation = gql`
  mutation upsertCertificate($input: AdminUpsertCertificateInput!) {
    upsertCertificate: adminUpsertCertificate(input: $input) {
      id
    }
  }
`;

export const updateCertificateDisplayOrderMutation = gql`
  mutation updateCertificateDisplayOrder(
    $input: [AdminUpdateCertificateDisplayOrderInput!]!
  ) {
    updateCertificateDisplayOrder: adminUpdateCertificateDisplayOrder(
      input: $input
    )
  }
`;

export const deleteCertificateMutation = gql`
  mutation deleteCertificate($id: ID!) {
    deleteCertificates: adminDeleteCertificate(id: $id)
  }
`;
