import { gql } from '@apollo/client';

export const slidesQuery = gql`
  query slides {
    slides: adminSlides {
      id
      displayOrder
      imagePath
      buttonLink
      translations {
        id
        languageIdentifier
        title
        description
        buttonLabel
      }
      translation {
        id
        languageIdentifier
        title
        description
        buttonLabel
      }
    }
  }
`;

export const upsertSlideMutation = gql`
  mutation upsertSlide($input: AdminUpsertSlideInput!) {
    upsertSlide: adminUpsertSlide(input: $input) {
      id
    }
  }
`;

export const updateSlideDisplayOrderMutation = gql`
  mutation updateSlideDisplayOrder(
    $input: [AdminUpdateSlideDisplayOrderInput!]!
  ) {
    updateSlideDisplayOrder: adminUpdateSlideDisplayOrder(input: $input)
  }
`;

export const deleteSlideMutation = gql`
  mutation deleteSlide($id: ID!) {
    deleteSlide: adminDeleteSlide(id: $id)
  }
`;
