import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Button,
  Layout,
  Spin,
  Empty,
  Image,
  Table,
  Tag,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import ModalUpsertPost from '../components/ModalUpsertPost';
import { postsQuery, deletePostMutation } from '../graphql/post';
import { ITEMS_PER_PAGE, resourceUrl, Routes } from '../constants';
import useQueryParams from '../components/useQueryParams';
import { useHistory } from 'react-router-dom';

const Posts = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [posts, setPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState([]);
  const queryParams = useQueryParams();
  const history = useHistory();

  const currentPage = queryParams.get('page') || 1;

  const { data, loading: postsLoading, refetch } = useQuery(postsQuery, {
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: ITEMS_PER_PAGE * (currentPage - 1),
    },
  });
  const [deletePost] = useMutation(deletePostMutation);

  useEffect(() => {
    if (data?.posts?.rows) {
      setPosts(data.posts.rows);
      setTotalPosts(data.posts.count);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = (shouldRedirect) => {
    setUpsertModalOpen(false);
    setSelectedItem(null);

    // redirect to first page if new post insert
    if (shouldRedirect) {
      history.push({
        pathname: Routes.POSTS,
      });
    }

    // refetch data on close modal
    refetch();
  };

  return (
    <Spin spinning={postsLoading}>
      {upsertModalOpen && (
        <ModalUpsertPost
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="News"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Post
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {posts.length === 0 && <Empty />}

        {posts.length > 0 && (
          <Table
            bordered
            size="small"
            rowKey={(record) => record.id}
            pagination={{
              total: totalPosts,
              pageSize: ITEMS_PER_PAGE,
              current: parseInt(currentPage, 10),
              defaultCurrent: 1,
              onChange: (page) => {
                history.push({
                  pathname: Routes.RECIPES,
                  search: `?page=${page}`,
                });
              },
            }}
            columns={[
              {
                title: '#',
                key: 'id',
                align: 'center',
                render: (_text, _record, index) => {
                  return index + 1;
                },
              },
              {
                title: 'Title',
                dataIndex: 'translation',
                key: 'translation',
                width: '100%',
                render: ({ title }) => title,
              },
              {
                title: 'Image',
                dataIndex: 'imagePath',
                key: 'imagePath',
                render: (imagePath) => (
                  <Image
                    src={`${resourceUrl}/${imagePath}`}
                    style={{ cursor: 'pointer' }}
                  />
                ),
              },
              {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                align: 'center',
                render: (tags) => (
                  <Space>
                    {tags.map(({ id, name }) => (
                      <Tag key={id} color="default">
                        #{name}
                      </Tag>
                    ))}
                  </Space>
                ),
              },
              {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (_text, record) => (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => openModal(record)}>
                          Edit
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item danger>
                          <Popconfirm
                            title="Really delete post?"
                            onConfirm={async () => {
                              await deletePost({
                                variables: { id: record.id },
                              });

                              await refetch();
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            Delete...
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                    arrow
                  >
                    <Button icon={<SettingOutlined />} />
                  </Dropdown>
                ),
              },
            ]}
            dataSource={posts}
          />
        )}
      </Layout.Content>
    </Spin>
  );
};

export default Posts;
