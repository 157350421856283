import React from 'react';
import { Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { resourceUrl } from '../../constants';

const PartnerCard = ({ partner, openModal, deletePartner }) => (
  <Card
    hoverable
    style={{ width: 240 }}
    cover={<img alt="partner" src={`${resourceUrl}${partner.imagePath}`} />}
    actions={[
      <EditOutlined key="edit" onClick={() => openModal(partner)} />,
      <Popconfirm
        title="Really delete partner?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => deletePartner(partner.id)}
      >
        <DeleteOutlined />
      </Popconfirm>,
    ]}
  >
    <Card.Meta
      title={<span>{partner.name}</span>}
      description={<span>Type: {partner.type}</span>}
    />
  </Card>
);

export default PartnerCard;
