import { gql } from '@apollo/client';

export const recipesQuery = gql`
  query recipes($limit: Int, $offset: Int) {
    recipes: adminRecipes(limit: $limit, offset: $offset) {
      rows {
        id
        slug
        imagePath
        tags {
          id
          name
        }
        translations {
          id
          languageIdentifier
          title
          text
        }
        translation {
          id
          languageIdentifier
          title
          text
        }
      }
      count
    }
  }
`;

export const upsertRecipeMutation = gql`
  mutation upsertRecipe($input: AdminUpsertRecipeInput!) {
    upsertRecipe: adminUpsertRecipe(input: $input) {
      id
    }
  }
`;

export const deleteRecipeMutation = gql`
  mutation deleteRecipe($id: ID!) {
    deleteRecipes: adminDeleteRecipe(id: $id)
  }
`;
