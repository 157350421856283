import { gql } from '@apollo/client';

export const collaboratorsQuery = gql`
  query collaborators {
    collaborators: adminCollaborators {
      id
      name
      code
      value
    }
  }
`;

export const upsertCollaboratorMutation = gql`
  mutation upsertCollaborator($input: AdminUpsertCollaboratorInput!) {
    upsertCollaborator: adminUpsertCollaborator(input: $input) {
      id
    }
  }
`;

export const deleteCollaboratorMutation = gql`
  mutation deleteCollaborator($id: ID!) {
    deleteCollaborators: adminDeleteCollaborator(id: $id)
  }
`;
