import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Modal,
  Select,
  Button,
  Spin,
  InputNumber,
  message,
} from 'antd';
import { useMutation } from '@apollo/client';
import { upsertCollaboratorMutation } from '../../graphql/collaborator';
import { counties } from '../../constants';

const ModalUpsertCollaborator = ({ selectedItem, modalOpen, closeModal }) => {
  const [form] = Form.useForm();

  const [
    upsertCollaborator,
    { loading: upsertCollaboratorLoading, error: upsertCollaboratorError },
  ] = useMutation(upsertCollaboratorMutation);

  useEffect(() => {
    if (upsertCollaboratorError) {
      message.destroy();
      message.error(upsertCollaboratorError.graphQLErrors[0].message);
    }
  }, [upsertCollaboratorError]);

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        ...selectedItem,
      });
    }
  }, [selectedItem, form]);

  return (
    <Modal
      keyboard={false}
      title={`${selectedItem ? 'Edit' : 'Create'} Collaborator`}
      afterClose={() => {
        form.resetFields();
      }}
      onCancel={() => closeModal()}
      maskClosable={false}
      forceRender={true}
      visible={modalOpen}
      footer={[
        <Button
          key="cancel"
          onClick={() => closeModal()}
          disabled={upsertCollaboratorLoading}
        >
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={upsertCollaboratorLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {selectedItem ? 'Save' : 'Create'}
        </Button>,
      ]}
    >
      <Spin spinning={upsertCollaboratorLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={async (data) => {
            // get county name by code
            const { name } = counties.find(({ code }) => code === data.code);

            const result = await upsertCollaborator({
              variables: {
                input: {
                  ...(selectedItem?.id && { id: selectedItem.id }),
                  ...data,
                  name,
                },
              },
            });

            if (result.data) {
              closeModal(!!!selectedItem);
            }
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="code"
                label="County"
                rules={[
                  {
                    required: true,
                    message: 'County is required',
                  },
                ]}
              >
                <Select placeholder="Please select" optionFilterProp="label">
                  {counties.map(({ name, code }) => (
                    <Select.Option key={code} value={code} label={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="value"
                label="Value"
                rules={[
                  {
                    required: true,
                    message: 'Value is required',
                  },
                ]}
              >
                <InputNumber min="0" max="99" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpsertCollaborator;
