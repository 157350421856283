import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from '../constants';
import withSession from './withSession';

const PrivateRoute = ({ component: Component, session, ...rest }) => {
  return (
    <Route {...rest}>
      {(props) =>
        session.user ? <Component {...props} /> : <Redirect to={Routes.LOGIN} />
      }
    </Route>
  );
};

export default withSession(PrivateRoute);
