import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Modal, Upload, Button, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import keyBy from 'lodash.keyby';
import { slidesQuery, upsertSlideMutation } from '../../graphql/slide';
import validateImageFile from '../../utils/validateImageFile';
import LanguageTabs from '../LanguageTabs';

const ModalUpsertSlide = ({ selectedItem, modalOpen, closeModal }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const [upsertSlide, { loading: upsertSlideLoading }] = useMutation(
    upsertSlideMutation
  );

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue({
        ...selectedItem,
        // convert translations array to keyed object
        translations: keyBy(selectedItem.translations, 'languageIdentifier'),
      });
    }
  }, [selectedItem, form]);

  return (
    <Modal
      title={`${selectedItem ? 'Edit' : 'Create'} Slide`}
      afterClose={() => {
        form.resetFields();
        setFileList([]);
      }}
      onCancel={closeModal}
      maskClosable={false}
      forceRender={true}
      visible={modalOpen}
      footer={[
        <Button key="cancel" onClick={closeModal} disabled={upsertSlideLoading}>
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={upsertSlideLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {selectedItem ? 'Save' : 'Create'}
        </Button>,
      ]}
    >
      <Spin spinning={upsertSlideLoading}>
        <Form
          layout="vertical"
          form={form}
          onFinishFailed={({ errorFields }) => setValidationErrors(errorFields)}
          onFinish={async (data) => {
            await upsertSlide({
              variables: {
                input: {
                  ...(selectedItem?.id && { id: selectedItem.id }),
                  ...data,
                  // convert transations back to array
                  translations: Object.entries(data.translations).map(
                    ([languageIdentifier, val]) => ({
                      languageIdentifier,
                      ...val,
                    })
                  ),
                },
              },
              refetchQueries: [
                {
                  query: slidesQuery,
                },
              ],
            });

            closeModal();
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <LanguageTabs validationErrors={validationErrors}>
                {(identifier) => (
                  <div>
                    <Form.Item
                      name={['translations', identifier, 'title']}
                      label="Title"
                      rules={[{ required: true, message: 'Title is required' }]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>

                    <Form.Item
                      name={['translations', identifier, 'description']}
                      label="Description"
                    >
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>

                    <Form.Item
                      name={['translations', identifier, 'buttonLabel']}
                      label="Button Label"
                      rules={[
                        { required: true, message: 'Button Label is required' },
                      ]}
                    >
                      <Input placeholder="Button Label" />
                    </Form.Item>
                  </div>
                )}
              </LanguageTabs>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="buttonLink"
                label="Button Link"
                rules={[{ required: true, message: 'Button Link is required' }]}
              >
                <Input placeholder="eg: /vital-berry-export/" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="image"
                label="Slide Image"
                valuePropName="file"
                getValueFromEvent={(e) => e.file}
                rules={[
                  {
                    required: !!!selectedItem,
                    message: 'Image is required',
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  beforeUpload={validateImageFile}
                  fileList={fileList}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  onChange={({ fileList }) => {
                    setFileList(fileList.slice(-1));
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalUpsertSlide;
