import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  Button,
  Layout,
  Form,
  Upload,
  Row,
  Col,
  Spin,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { uploadFileMutation } from '../graphql/index';
import { techSpecFileName } from '../constants';

const Settings = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const [
    uploadFile,
    { loading: uploadFileLoading, error: uploadFileError },
  ] = useMutation(uploadFileMutation);

  useEffect(() => {
    if (uploadFileError) {
      message.destroy();
      message.error(uploadFileError.graphQLErrors[0].message);
    }
  }, [uploadFileError]);

  return (
    <Spin spinning={uploadFileLoading}>
      <PageHeader className="page-header" title="Settings" />

      <Layout.Content className="page-content">
        <Form
          layout="horizontal"
          form={form}
          onFinish={async (data) => {
            const result = await uploadFile({
              variables: {
                input: {
                  name: techSpecFileName,
                  file: data.file,
                },
              },
            });

            if (result.data) {
              // clear file list
              setFileList([]);

              // show success message
              message.success('File uploaded successfully');
            }
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="file"
                label="Technical Specification"
                valuePropName="file"
                getValueFromEvent={(e) => e.file}
                rules={[
                  {
                    required: true,
                    message: 'File is required',
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  accept=".pdf"
                  beforeUpload={() => false}
                  fileList={fileList}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  onChange={({ fileList }) => {
                    setFileList(fileList.slice(-1));
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Spin>
  );
};

export default Settings;
