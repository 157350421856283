import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Layout, Row, Col, Spin, Empty } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import CertificateCard from '../components/CertificateCard';
import ModalUpsertCertificate from '../components/ModalUpsertCertificate';
import {
  certificatesQuery,
  updateCertificateDisplayOrderMutation,
  deleteCertificateMutation,
} from '../graphql/certificate';

const Certificates = () => {
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [certificates, setCertificates] = useState([]);

  const { data, loading: certificatesLoading } = useQuery(certificatesQuery);
  const [updateCertificateDisplayOrder] = useMutation(
    updateCertificateDisplayOrderMutation
  );
  const [deleteCertificate] = useMutation(deleteCertificateMutation);

  useEffect(() => {
    if (data?.certificates) {
      setCertificates(data.certificates);
    }
  }, [data]);

  const openModal = (selectedItem = null) => {
    setUpsertModalOpen(true);
    setSelectedItem(selectedItem);
  };

  const closeModal = () => {
    setUpsertModalOpen(false);
    setSelectedItem(null);
  };

  const SortableItem = SortableElement(({ certificate }) => {
    return (
      <Col style={{ userSelect: 'none' }}>
        <CertificateCard
          certificate={certificate}
          openModal={openModal}
          deleteCertificate={async (id) => {
            await deleteCertificate({
              variables: { id },
              refetchQueries: [{ query: certificatesQuery }],
            });
          }}
        />
      </Col>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row gutter={[18, 18]} style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            certificate={value}
          />
        ))}
      </Row>
    );
  });

  return (
    <Spin spinning={certificatesLoading}>
      {upsertModalOpen && (
        <ModalUpsertCertificate
          modalOpen={upsertModalOpen}
          selectedItem={selectedItem}
          closeModal={closeModal}
        />
      )}

      <PageHeader
        className="page-header"
        title="Certificates"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => openModal()}
          >
            Add Certificate
          </Button>
        }
      />

      <Layout.Content className="page-content">
        {certificates.length === 0 && <Empty />}

        <SortableList
          distance={5}
          axis="x"
          items={certificates}
          onSortEnd={async ({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex) {
              return;
            }

            const sortedCertificates = arrayMove(
              certificates,
              oldIndex,
              newIndex
            );

            // update state
            setCertificates(sortedCertificates);

            // update db
            await updateCertificateDisplayOrder({
              variables: {
                input: sortedCertificates.reduce((acc, certificate, index) => {
                  acc.push({ id: certificate.id, displayOrder: index });
                  return acc;
                }, []),
              },
            });
          }}
        />
      </Layout.Content>
    </Spin>
  );
};

export default Certificates;
