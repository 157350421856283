import React from 'react';
import { Menu } from 'antd';
import {
  LogoutOutlined,
  DashboardOutlined,
  RightCircleOutlined,
  FileDoneOutlined,
  TeamOutlined,
  TagsOutlined,
  CameraOutlined,
  BookOutlined,
  NotificationOutlined,
  ApartmentOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Routes } from '../../constants';
import './style.scss';
import withSession from '../withSession';

const Navigation = ({ session }) => {
  const location = useLocation();

  return (
    <>
      <div className="logo" />
      <Menu
        theme="dark"
        className="menu"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item
          key={Routes.INDEX}
          icon={<DashboardOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.INDEX}>Dashboard</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.POSTS}
          icon={<NotificationOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.POSTS}>News</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.RECIPES}
          icon={<BookOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.RECIPES}>Recipes</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.GALLERIES}
          icon={<CameraOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.GALLERIES}>Galleries</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.SLIDES}
          icon={<RightCircleOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.SLIDES}>Slides</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.CERTIFICATES}
          icon={<FileDoneOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.CERTIFICATES}>Certificates</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.PARTNERS}
          icon={<TeamOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.PARTNERS}>Partners</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.TAGS}
          icon={<TagsOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.TAGS}>Tags</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.COLLABORATORS}
          icon={<ApartmentOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.COLLABORATORS}>Collaborators</Link>
        </Menu.Item>

        <Menu.Item
          key={Routes.SETTINGS}
          icon={<SettingOutlined style={{ fontSize: 24 }} />}
        >
          <Link to={Routes.SETTINGS}>Settings</Link>
        </Menu.Item>

        <Menu.Item
          onClick={() => session.logOut()}
          icon={<LogoutOutlined style={{ fontSize: 24 }} />}
        >
          Log out
        </Menu.Item>
      </Menu>
    </>
  );
};

export default withSession(Navigation);
